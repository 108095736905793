<template>
	<div>
		<div class="contactUs">
			<tap :taps="['联系我们']" :isMore="false"></tap>
			<div class="map" ref="map" v-loading="loading">
				<div class="content" v-if="!loading">
					<div class="title">{{title}}</div>
					<div>
						<div class="tips">电话</div>
						<div class="val">0931-8732152</div>
					</div>
					<div>
						<div class="tips">传真</div>
						<div class="val">0931-8732152</div>
					</div>
					<div>
						<div class="tips">地址</div>
						<div class="val">甘肃省兰州市城关区农民巷8号之1号盛达金融大厦21层</div>
					</div>
					<div>
						<div class="tips">网址</div>
						<div class="val">http://www.gsotc.com.cn/</div>
					</div>
				</div>
			</div>

		</div>
		<div class="leaving-msg">
			<tap :taps="['在线留言']" :isMore="false"></tap>
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="姓名">
					<el-input v-model="form.name" class="inp" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="电话">
					<el-input type="number" v-model="form.phone" class="inp" placeholder="请输入电话"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input v-model="form.mailbox" class="inp" placeholder="请输入邮箱"></el-input>
				</el-form-item>
				<el-form-item label="留言类型">
					<el-select v-model="form.mestype" class="sel" placeholder="请选择留言类型">
						<el-option
						    v-for="item in options"
						    :key="item.value"
						    :label="item.label"
						    :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标题">
					<el-input v-model="form.title" class="title" placeholder="请输入标题"></el-input>
				</el-form-item>
				<el-form-item label="详细描述">
					<el-input
						type="textarea"
						:rows="4"
						placeholder="请输入您要留言详细描述"
						v-model="form.details"
						class="title">
					</el-input>
				</el-form-item>
				<el-form-item label="上传文件">
					<el-upload class="upload-demo" 
						action="http://gansu.web.zhongwangyingtong.com/index.php/attachment/upload/upload"
						:data="{dir:'file',module:'cms'}"
						:on-success="sucFile">
						<el-button type="primary">上传文件</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="上传图片">
					<div class="up-img">
						<div class="img" v-show="form.imgPath">
							<img :src="form.imgPath" alt="">
						</div>
						<el-upload class="avatar-uploader"
							action="http://gansu.web.zhongwangyingtong.com/index.php/attachment/upload/upload"
							:data="{dir:'images',module:'cms'}"
							:on-success="sucImg"
							:show-file-list="false">
							<div class="upload-img">
								<i class="el-icon-plus"></i>
								<div>选择图片</div>
							</div>
						</el-upload>
					</div>
				</el-form-item>
			</el-form>
			<el-button type="primary" style="margin-left: 80px;" @click="clkUpload">点击提交</el-button>
		</div>
	</div>
</template>

<script>
	import qs from "qs"
	export default {
		data() {
			return {
				url: "https://img.js.design/assets/img/6291e9862be5113740c82b6a.png",
				form: {
					imgPath:""
				},
				marker: null,
				map: null,
				lat: 36.052708,
				lng: 103.85599,
				title: "甘肃股权交易中心",
				fileList: [],
				loading: false,
				options:[{
				    value: '选项1',
				    label: '类型1'
				}, {
				    value: '选项2',
				    label: '类型2'
				}, {
				    value: '选项3',
				    label: '类型3'
				}]
			}
		},
		mounted() {
			this.getdata();
		},
		methods: {
			clkUpload() {
				if(!this.form.name) {
					this.$message.error('请输入姓名');
					return false
				}
				if(!this.form.phone) {
					this.$message.error('请输入电话');
					return false
				}
				if(!(/^1[3456789]\d{9}$/.test(this.form.phone))) {
					this.$message.error('电话号码格式错误');
					return false
				}
				if(!this.form.mailbox) {
					this.$message.error('请输入邮箱');
					return false
				}
				
				if(!this.form.mestype) {
					this.$message.error('请选择留言类型');
					return false
				}
				
				if(!this.form.title) {
					this.$message.error('请输入标题');
					return false
				}
				
				if(!this.form.details) {
					this.$message.error('请输入简介');
					return false
				}
				
				if(!this.form.uploadfile) {
					this.$message.error('请上传文件');
					return false
				}
				
				if(!this.form.thumb) {
					this.$message.error('请上传图片');
					return false
				}
				
				
				let url = this.$api.about.OnlineMessage
				this.$axios.post(
					url,
					qs.stringify(this.form)
				).then(res=>{
					if(res.code) {
						this.$message.success(res.data);
						this.form = {imgPath:""}
					}
				})
			},
			getdata() {
				this.loading = true;
				setTimeout(() => {
					this.loading = false;
					this.initmap();
				}, 1000)
			},
			initmap() {
				let center = new window.TMap.LatLng(this.lat, this.lng);
				let dom = this.$refs.map;
				this.map = new window.TMap.Map(dom, {
					center, //地图中心点
					zoom: 17.2, // 设置地图缩放级别
					picth: 43.5,
					rotation: 444
				})
				this.map.on("click", this.getpoint);
				this.createdMarter();
			},
			getpoint(ev) {
				this.title = ev.poi.name;
				this.lat = ev.latLng.lat.toFixed(6);
				this.lng = ev.latLng.lng.toFixed(6);
				this.createdMarter();
			},
			createdMarter() {
				if (this.marker) {
					this.marker.setMap(null);
					this.marker = null;
					this.createdMarter();
				} else {
					this.marker = new window.TMap.MultiMarker({
						"id": 'marker-layer',
						"map": this.map,
						"styles": {
							"marker": new window.TMap.MarkerStyle({
								"width": 25,
								"height": 35,
								"anchor": {
									x: 16,
									y: 32
								},
								"src": "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png"
							})
						},
						geometries: [{
							"id": "demo",
							"styleId": "marter",
							"position": new window.TMap.LatLng(this.lat, this.lng),
							"properties": {
								"title": "marker"
							}
						}]
					})
				}
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
			 console.log(file);
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			// 上传图片成功后的
			sucImg(response) {
				let path = "http://gansu.web.zhongwangyingtong.com"+response.path
				this.form.thumb = response.id
				this.form.imgPath = path
			},
			// 上传文件成功后的
			sucFile(res) {
				this.form.uploadfile = res.id
			}
		}
	}
</script>

<style lang="less">
	.el-button--primary {
		background-color: var(--themeColor);
		border: none;
		border-radius: 0;
		&:hover {
			background-color: rgba(10, 72, 144, .3);
		}
	}
	.up-img {
		display: flex;
		.img {
			img {
				width: 117px;
				height: 117px;
				object-fit: cover;
			}
		}
	}
	.map {
		position: relative;
		height: 530px;

		.content {
			color: #333;
			position: absolute;
			bottom: 0;
			right: 20px;
			z-index: 2;
			width: 40%;
			background: rgba(255, 255, 255, 0.92);
			padding: 30px;

			>div {
				margin: 25px 0;

				.tips {
					color: #999;
					font-size: 14px;
					font-weight: 400;
				}

				.val {
					font-size: 16px;
					font-weight: 500;
					line-height: 1.2em;
					word-break: break-all;
					margin: 10px 0;
				}
			}

			.title {
				font-size: 22px;
				font-weight: 700;
			}
		}
	}

	.leaving-msg {
		margin: 40px 0;

		.inp {
			max-width: 600px;
		}

		.title {
			max-width: 600px;
		}
		.sel {
			width: 600px;
		}

		.upload-img {
			width: 115px;
			height: 115px;
			border: 1px solid #c2c6cd;
			text-align: center;

			.el-icon-plus {
				font-size: 30px;
				margin: 28px 0 10px;
			}

			font-size: 18px;
		}
	}
	@media (max-width:768px) {
		.rotate-circle {
			display: none !important;
		}
		.tmap-zoom-control {
			display: none !important;
		}
		.tmap-scale-control {
			display: none !important;
		}
		.logo-text {
			display: none !important;
		}
		.el-form-item {
			margin-bottom: 15px;
		}
		.leaving-msg {
			margin: 20px 0;
			.sel {
				width: 100%;
			}
		}
		
		.map {
			height: 400px;
			.content {
				right: 0;
				width: 100%;
				left: 0;
				padding: 0;
				.title {
					font-size: 18px;
				}
				&>div {
					margin: 10px 0;
					.val {
						font-size:14px;
						margin: 5px 0;
					}
				}
			}
		} 
	}
</style>
